<template>
  <div>
    <b-card title="Resumen Estructura" class="text-center">
      <b-row class="mb-2">
      <div style="width: 100% ;">
          <table id="tap" class="table" v-if="tapeta[0]" style="height: 25px">
            <tr><td class="tapeta" colspan="3"></td></tr>
          </table>      
        <table id="registro" class="table" :style="'height :'+ register_y +'px'" v-if="register">
          <tr>
            <td class="tapeta" v-if="tapeta[2]"></td>            
            <td :colspan="vdivisions" style="border : 1px solid black">
                <div class="d-flex innerDiv1" style="height : 100% ; flex-direction : column; justify-content: space-evenly">
                </div>          
            </td>
            <td class="tapeta" v-if="tapeta[3]"></td>                        
          </tr>
        </table>             
          <table id="esquema" class="table table-bordered" :style="'height :'+ table_y +'px'">
            <tr>
              <td class="tapeta" v-if="tapeta[2]"></td>              
              <td v-for="vdivision,index in dessign" :key="index" :style="'width : '+ (vdivision.percent/100) * table_x +'px'">
                <div class="d-flex outerDiv1" style="">
                  <div v-for="hdivision,key in vdivision.rows" :key="key" 
                    :style="'height : '+ hdivision.percent +'%'"
                  > 
                  <div v-if="hdivision.element" :style="'background-image : url(\'' +  $http.defaults.baseURL + 'storage/app/private/' +  hdivision.element.image+'\')'" class="d-flex innerDiv1"></div>
                  <div class="d-flex innerDiv1" v-else></div>
                  </div>
                </div>
              </td>
              <td class="tapeta" v-if="tapeta[3]"></td>
            </tr>
          </table>
          <table id="bot" class="table" v-if="tapeta[1]" style="height: 25px">
            <tr><td class="tapeta" colspan="3"></td></tr>
          </table>              
        </div>          
      </b-row>
      <app-collapse accordion type="margin" >
        <app-collapse-item title="Datos estructura">
            <dl class="row" v-for="item, index in arr_complements" v-bind:key="index">
                <dt class="col-5 col-sm-3 text-left">{{item.complemento}}</dt>
                <dd class="col-7 col-sm-9 text-left">{{item.valor}}</dd>
            </dl>
        </app-collapse-item>
        <app-collapse-item title="Elementos">
          <b-table
            thead-class="d-none"
            tbody-class="text-left"
            :fields="fields2"
            borderless="borderless"
            responsive="sm"
            :items="elements"
          >        
           <template #cell(image)="data">
              <img class="img-td" :src=" $http.defaults.baseURL+'storage/app/private/'+data.value">
           </template>
          </b-table>
        </app-collapse-item>
        <app-collapse-item title="Tapetas">
          <div class="text-left ml-5">
             <b-badge class="mr-1" pill variant="light-secondary" v-for="item,index in tapeta" v-if="item">{{t_position[index]}} ({{item}})</b-badge>
          </div>          
        </app-collapse-item>
        <app-collapse-item title="Comentarios">
            <b-form-textarea
              id="comments"
              size="sm"
              name="comments"
              v-model="comments"
              placeholder="Observaciones"
              no-resize
            />
        </app-collapse-item>
      </app-collapse>

      <b-button type="button" class="mt-0" variant="success" block @click="next"> Añadir Estructura </b-button>
      <b-button type="button" class="mt-1" variant="danger" block @click="reset"> Cancelar </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {BCard, BCol, BRow, BButton, BFormTextarea, BImg, BBadge, BTable} from "bootstrap-vue";
export default {
  components: {BCard, BCol, BRow, BButton, BFormTextarea, AppCollapse, AppCollapseItem, BImg, BBadge, BTable},
  data() {
    return {
      dessign : [],
      elements : [],
      arr_complements : [],
      t_position : ['Arriba','Abajo','izquierda', 'Derecha'],
      comments : '',
      table_x : 0,            
      fields : [
        {key : 'complemento'},
        {key : 'valor'},
      ],
      fields2 : [
        {key : 'name'},
        {key : 'image'},
      ],
      table_y : null,
      register_y :  null
    };
  },
  created () {
    this.dessign = this.divisions
    for (let i = 0; i < this.dessign.length; i++) {
      this.dessign[i].percent = (this.dessign[i].width / this.total_width) * 100
      for (let j = 0; j < this.dessign[i].rows.length; j++) {
        this.dessign[i].rows[j].percent = (this.dessign[i].rows[j].height  / this.total_height) * 100;
        if (this.dessign[i].rows[j].element) {
          this.elements.push(this.dessign[i].rows[j].element)
        }
      }      
    }
    if(this.complements.persiana){
      this.arr_complements.push({complemento : 'Con persiana' , valor : this.complements.persiana_desc});
    }
    if(this.complements.name){
      this.arr_complements.push({complemento : 'Nombre' , valor : this.complements.name});
    }
    if(this.complements.registro){
      this.arr_complements.push({complemento : 'Con registro' , valor : this.complements.registro_medida + ' / ' + (this.complements.registro_tipo == 0 ? 'Tapeta' : 'Hueco de luz')});
    }
  },
  mounted () {
    let clientWidth = document.getElementById('esquema').clientWidth;
    clientWidth = clientWidth > 600 ? 600 : clientWidth;
    clientWidth = this.tapeta[2] ? (clientWidth -25) : clientWidth;
    clientWidth = this.tapeta[3] ? (clientWidth -25) : clientWidth;
    this.table_x = clientWidth;              
    this.table_y = (clientWidth * this.total_height) / this.total_width;
    this.register_y = (clientWidth * this.register) / this.total_width;    
  },      
  methods : {
    next() {
      this.$http.post("/api/InsertStructure",{user : this.user.id,log : this.full_state, comments : this.comments }).then((response) => {
        if (response.data) {
          this.$router.push('/order');
        }else {
          alert('Los datos no han podido ser almacenados')
        }
      });       
    },
    reset() {
      this.$router.push('/order');
    }
  },
    computed: {
    ...mapState({
      register : state => state.ventanas.complements.registro_medida,            
      user: state => state.auth.user,         
      divisions: state => state.ventanas.divisions,
      complements: state => state.ventanas.complements,
      tapeta : state => state.ventanas.complements.tapeta_values,        
      total_width : state => state.ventanas.dimensions.width,
      total_height : state => state.ventanas.dimensions.height,
      full_state : state => state.ventanas      
    }),
  }
};
</script>

}<style>
.innerDiv1{
  height: 100%;
  align-items : center;
  border : 1px solid black;
  padding : 5px ;
  background-color : white !important;
  background-color : lightgray !important;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.outerDiv1{
  height : 100% ;
  flex-direction : column;
  justify-content: space-evenly
}
.img-td {
  max-height: 50px;
}
</style>